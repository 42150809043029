import React, { useMemo, useEffect } from 'react';
import { getCurrentHub as SentryGetCurrentHub, configureScope as SentryConfigureScope } from '@sentry/nextjs';
import dynamic from 'next/dynamic';

import Loader from 'components/uiLibrary/Loader';
import ErrorPage from 'containers/ErrorPage';

import { ENTITY_TYPE, URL_STATIC_PATHS, RESPONSE_STATUS_CODES, ENTITY_MAIN_TABS } from 'constants/index';

import getServerProps from 'utils/globals/ssrQueries';
import usePageContext from 'utils/hooks/usePageContext';
import withAuthCheck from 'utils/hocs/withAuthCheck';
import useTracking from 'components/Globals/Analytics';
import { getSentryTransactionName } from 'utils/globals/sentry';
import { PAGE_CATEGORY, PAGE_TYPES } from 'components/Globals/Analytics/constants';

import { getPageTranslations } from 'src/i18n';
import orgReducer, { key as orgKey } from 'containers/Organizations/reducer';
import artistReducer, { key as artistKey } from 'containers/Artist/reducer';
import addRepertoireReducer, { key as addRepertoireKey } from 'containers/AddRepertoire/reducer';
import editImagesReducer, { key as editImagesKey } from 'containers/EditImages/reducer';
import validationReducer, { key as validationKey } from 'containers/Validation/reducer';
import { useInjectReducer } from 'store/injectReducer';
import IntermediatePopupProvider from 'utils/hooks/useIntermediatePopup';
import PageWrapper from 'components/Globals/Layout/PageWrapper';
import { useEntitySEO } from 'utils/seo/index';
import { useEntitySectionTabs } from 'utils/globals';
import { useActiveProfileId, shouldAllowEditMode } from 'utils/hooks/useAuthenticatedUser';

// TODO: Hack for dynamic import CSS missing issue - with assumption that users will be navigating to home of that entity
// TODO: Remove this once next js solves for this issue https://github.com/vercel/next.js/issues/33286
import Home from 'components/Home';

const BaseWrapper = dynamic(() => import('components/Globals/Layout/StubPage/BaseWrapper'));

const OrganizationEdit = dynamic(() => import('components/Organization/Edit'));
const ArtistEdit = dynamic(() => import('components/Artist/Edit'));
const ModifyProduction = dynamic(() => import('components/Productions/Edit/ModifyProduction'));
const SearchProductions = dynamic(() => import('components/Productions/Edit/SearchProductions'));
const ReviveProduction = dynamic(() => import('components/Productions/Edit/ReviveProduction'));
const ValidateProduction = dynamic(() => import('components/Productions/Edit/ValidateProduction'));

const EditPages = withAuthCheck(() => {
  useInjectReducer([
    { key: orgKey, reducer: orgReducer },
    { key: artistKey, reducer: artistReducer },
    { key: addRepertoireKey, reducer: addRepertoireReducer },
    { key: editImagesKey, reducer: editImagesReducer },
    { key: validationKey, reducer: validationReducer },
  ]);
  const { entityType, mainPath, entity, isLoading } = usePageContext();

  if (isLoading) {
    return <Loader />;
  }

  if (!entity?.id) {
    return <ErrorPage statusCode={404} />;
  }

  if (mainPath) {
    switch (mainPath) {
      case URL_STATIC_PATHS.MODIFY_PRODUCTION: {
        return <ModifyProduction />;
      }
      case URL_STATIC_PATHS.REVIVALS: {
        return <ReviveProduction />;
      }
      case URL_STATIC_PATHS.SEARCH_PRODUCTIONS: {
        return <SearchProductions />;
      }
      case URL_STATIC_PATHS.VALIDATION: {
        if (entityType === ENTITY_TYPE.ARTIST) {
          return <ValidateProduction />;
        }

        return <ErrorPage statusCode={403} />;
      }
      default: {
        return <ErrorPage statusCode={403} />;
      }
    }
  }

  if (entityType === ENTITY_TYPE.ORGANIZATION) {
    return <OrganizationEdit />;
  }

  if (entityType === ENTITY_TYPE.ARTIST) {
    return (
      <IntermediatePopupProvider>
        <ArtistEdit />
      </IntermediatePopupProvider>
    );
  }

  return <ErrorPage statusCode={403} />;
});

const EntityStubPages = ({ statusCode }) => {
  const { entityType, mainPath, entity, editModeToggle } = usePageContext();
  const isEditMode = shouldAllowEditMode({
    activeProfileId: useActiveProfileId(),
    entityId: entity?.id,
    entityType,
    editModeToggle,
  });
  const tabs = useEntitySectionTabs({ entityType, entity, isEditMode });
  const activeTab = useMemo(() => tabs?.find(({ key }) => key === mainPath), [mainPath, tabs]) || {};

  const seo = useEntitySEO({ entityType, entity, activeTab });

  if (
    statusCode !== RESPONSE_STATUS_CODES.SUCCESS ||
    (!mainPath && ![ENTITY_TYPE.ORGANIZATION, ENTITY_TYPE.ARTIST].includes(entityType)) ||
    (mainPath && !Object.values(ENTITY_MAIN_TABS).includes(mainPath))
  ) {
    return <ErrorPage statusCode={statusCode} />;
  }

  return (
    <PageWrapper seo={seo} entityType={entityType} hideGlobalNavigation={isEditMode}>
      <BaseWrapper isEditMode={isEditMode} mainPath={mainPath} />
    </PageWrapper>
  );
};

const RootPage = ({ statusCode }) => {
  const { entityType, entityId, entity, mainPath, subPath, edit, paths } = usePageContext();
  const track = useTracking();

  useEffect(() => {
    const sentryTransactionName = getSentryTransactionName({ entityType, entityId, mainPath, subPath, edit, paths });
    const transaction = SentryGetCurrentHub()
      ?.getScope()
      ?.getTransaction();

    if (transaction && sentryTransactionName) {
      transaction.setName(sentryTransactionName);
    }
  }, [entityType, entityId, mainPath, subPath, edit, paths]);

  useEffect(() => {
    if (entityType) {
      if (entity) {
        if (entityType === ENTITY_TYPE.ARTIST) {
          track.pageView({ pageCategory: PAGE_CATEGORY.STUB, pageType: PAGE_TYPES.ARTIST });
        } else if (entityType === ENTITY_TYPE.ORGANIZATION) {
          track.pageView({ pageCategory: PAGE_CATEGORY.STUB, pageType: entity?.organizationType?.slug || '' });
        }
      }
    } else {
      track.pageView({ pageCategory: PAGE_CATEGORY.LANDING, pageType: PAGE_TYPES.HOME });
    }
  }, [track, entityType, entity]);

  if (entityType) {
    if (edit) {
      return <EditPages />;
    }

    if (statusCode !== RESPONSE_STATUS_CODES.SUCCESS) {
      return <ErrorPage statusCode={statusCode} />;
    }

    return <EntityStubPages statusCode={statusCode} />;
  }

  if (paths?.length > 0) {
    return <ErrorPage statusCode={404} />;
  }

  return <Home />;
};

RootPage.i18nNamespaces = getPageTranslations([
  'NS_ENTITY_STUB_PAGE',
  'NS_CONVERSION_FLOW',
  'NS_INSIGHTS',
  'EDIT_MODE_SUBSCRIPTION_NUDGE',
]);

export async function getServerSideProps(ctx) {
  const { redirect, notFound, context, ...serverProps } = await getServerProps(ctx);
  const { entityType, paths } = context;

  if (redirect?.destination) {
    ctx.res.writeHead(redirect?.statusCode, { location: redirect?.destination });
    ctx.res.end();
  }

  SentryConfigureScope(scope => {
    scope.setTransactionName(getSentryTransactionName(context));
  });

  return {
    ...serverProps,
    notFound: notFound || (!entityType && paths?.length > 0),
  };
}

export default RootPage;

// TODO: FB-25982 Remove separate component for this, add to home page itself
import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import FeaturedArtistsNew from 'components/Artist/Display/FeaturedArtistsNew';
import Typography from 'components/uiLibrary/Typography';
import ProfessionSelectSection from 'components/Artist/ArtistIndexPage/ProfessionSelectSection';
import HeadingSection from 'components/Artist/ArtistIndexPage/HeadingSection';

import { useTranslation } from 'src/i18n';
import { TP } from 'constants/index';

import { SECTIONS, COMPONENTS, CATEGORIES } from 'components/Globals/Analytics/constants';

import classes from './FeaturedArtistsSection.module.scss';

const featuredArtistTrackingData = {
  section: SECTIONS.RECOMMENDED_ARTISTS,
  component: COMPONENTS.ARTIST_CARD,
};

const seeAllFeaturedArtistTrackingData = {
  section: SECTIONS.RECOMMENDED_ARTISTS,
  component: COMPONENTS.SEE_ALL_CTA,
};

const castingToolBannerTrackingData = {
  section: SECTIONS.RECOMMENDED_ARTISTS,
};

const headerTrackingData = {
  section: SECTIONS.HEADER,
};

const artistSearchTrackingData = {
  section: SECTIONS.RECOMMENDED_ARTISTS,
  component: COMPONENTS.ARTIST_SEARCH,
  category: CATEGORIES.SEARCH,
};

const FeaturedArtistsSection = () => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const isTablet = useMediaQuery('(max-width:1024px)');

  return (
    <div className={classes.fas}>
      <div className={classes.fas__title}>
        <div className={classes.fas__title_desktop}>
          <HeadingSection trackingData={headerTrackingData} />
        </div>
        {isTablet && (
          <div className={classes.fas__title_mobile}>
            <Typography variant="h2" size={20} weight="bold">
              {t(`${TP}.m_ARTISTS`)}
            </Typography>
          </div>
        )}
      </div>
      <div className={classes.fas__searchSelectSection}>
        <ProfessionSelectSection
          showAllProfessions
          className={classes.searchSelect}
          artistSearchTrackingData={artistSearchTrackingData}
        />
      </div>
      <div className={classes.fas__faq}>
        <FeaturedArtistsNew
          professionsLimit={10}
          artistsLimit={4}
          trackingData={featuredArtistTrackingData}
          seeAllArtistTrackingData={seeAllFeaturedArtistTrackingData}
          castingToolBannerTrackingData={castingToolBannerTrackingData}
        />
      </div>
    </div>
  );
};

export default FeaturedArtistsSection;

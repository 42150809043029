import React, { useEffect, useMemo } from 'react';

import usePageContext from 'utils/hooks/usePageContext';
import { BASE_PAGE_ROUTES } from 'constants/index';

const withAuthCheck = Component => ({ ...props }) => {
  const { navigate, pathname, permissions } = usePageContext();
  const isLoggedIn = useMemo(() => permissions?.isLoggedIn, [permissions]);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate.to(navigate.getLinkProps({ baseRoute: BASE_PAGE_ROUTES.LOGIN, queryParams: { page: pathname } }), true);
    }
  }, [navigate, pathname, isLoggedIn]);

  if (isLoggedIn) {
    return <Component {...props} />;
  }

  return null;
};

export default withAuthCheck;
